
header img,
footer img {max-width: 100%; height: auto; border: none;}

header,
footer {font-size: 12px; line-height: 1.5;}
header .header_wrap,
footer .footer_wrap {max-width: 1200px; box-sizing: content-box; margin: 0 auto;}
header .header_wrap {min-height: 40px; padding: 10px 3%; display: flex; justify-content: space-between; align-items: center;}
footer .footer_wrap {padding: 1em 3% 2em;}

@media (min-width: 640px) {
	header,
	footer {font-size: 14px;}
	header .header_wrap {min-height: 60px;}
	footer .footer_wrap {padding-top: 2em; padding-bottom: 5em;}
}

header ul,
footer ul {list-style: none; padding: 0;}

header a,
footer a {color: inherit; text-decoration: none;}
header ul.header_menu a:hover,
footer a:hover {text-decoration: underline;}

header {border-bottom: solid 1px #0056A5;}
header p.logo_wrap {margin: 0; font-weight: bold; line-height: 1.2;}
header p.logo_wrap .site_logo {color: #000; display: flex; align-items: center; font-size: 24px;}
header p.logo_wrap a:hover {text-decoration: none;}
header p.logo_wrap img {width: 130px;}
header p.logo_wrap span.ff_g {margin-left: 0.5em;}
@media (min-width: 640px) {
	header p.logo_wrap img {width: 190px;}
	header p.logo_wrap span.ff_g {margin-left: em;}
}


header ul.header_menu img {width: 130px;}



footer {border-top: solid 1px #C5C5C5;}
footer ul {display: flex; flex-wrap: wrap; padding: 0;}
footer ul li {margin: 0.3em 0;}
footer ul li::after {content: ""; display: inline-block; width: 1px; height: 15px; margin: 0 1em; vertical-align: -0.25em; background: #C5C5C5;}
footer ul li:last-child::after {content: none;}
footer ul + ul {margin-bottom: 2em;}
footer p.copy {margin: 0;}
