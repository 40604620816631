.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-calendar {
  width: 900px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

/* div.title {
  color: white;
  background-color: blue;
  text-align: center;
  margin: 10px;
} */
/* div.top {
  margin: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
} */

div.mainContent {
  /* border: gold solid; */
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  /* padding: 10pt; */
}

div.address {
  /* border: solid 2px; */
  padding: 5px;
  margin: 5px;
}

div.calendar-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

div.result-calendar {
  /* border: solid 2px; */
  padding: 5px;
  margin: 5px;
}

span.smallfont {
  font-size: 12px;
}
span.colum {
  font-size: 120%;
  letter-spacing: 0.05em;
  font-feature-settings: "palt";
  color: rgba(0, 0, 0, 0.788);
  font-weight: bold;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "hirakakupro-w3", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic",
    "メイリオ", "Meiryo";
}
.mainContent {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "hirakakupro-w3", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic",
    "メイリオ", "Meiryo";
}
.box_outofservice {
  background: #ffccd9;
  padding: 1em;
  border-radius: 0.5em;
  border: solid 2px #f50d0d;
  margin: 0.5em 0;
}
.box_outofservice:first-child {
  margin-top: 0;
}

div.update {
  text-align: right;
}

div.divbutton {
  margin: 10px 10px 10px 0;
}
.MuiButton-label {
  /* margin: 10px; */
  font-size: 18px;
}

div.annotation {
  font-size: 15px;
}

div.address_select {
  margin: 1px;
}
